<template>
    <keep-alive>
        <router-view/>
    </keep-alive>
</template>

<script>
export default {
    name: 'HomeHousewarmingIndex',
    data:() =>({
    }),
    computed: {
    },
    watch:{
    },
    async created() {},
    // keep alive reload
    activated() {
	},
    mounted() {
	},
    destroyed(){
    },
	methods: {}
}
</script>

<style scoped lang="scss">
</style>
